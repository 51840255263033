.wrapper {
  width: 100%;
  padding-bottom: 120px;
}

.header {
  width: 1150px;
  margin: auto;
}

.title {
  text-align: left;
}

.red {
  color: red;
}

.green {
  color: green;
}

.premium {
  width: 24px;
}

.addUserBlock {
  margin: 30px 0;
}

.addUserWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
