.wrapper {
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.chartWrap{
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
}

.yAx text{
  color: red
}